<template>
  <div class="registration-email">
    <LayoutViewWrapperNarrow>
      <div class="content">
        <BaseH1 :text="'Rejestracja'" :short="true" />

        <div class="wrapper-inputs">
          <InputTextEmailValidation
            v-model="state.login"
            @update:is-valid-email="state.isValidLogin = $event"
          />
          <InputTextPasswordValidation
            v-model="state.password"
            @update:is-valid="state.isValidPassword = $event"
          />
        </div>

        <ButtonPrimaryNext
          @click="handlePreRegistration"
          :isDisabled="isButtonDisabled"
        />
      </div>
    </LayoutViewWrapperNarrow>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";

import { ROUTE_REGISTRATION_IDENTIFICATION_NAME } from "@/router/constants";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputTextPasswordValidation from "@/components/UI/InputTextPasswordValidation.vue";
import InputTextEmailValidation from "@/components/UI/InputTextEmailValidation.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import useAuth from "@/composables/useAuth";

export default {
  components: {
    BaseH1,
    InputTextPasswordValidation,
    InputTextEmailValidation,
    ButtonPrimaryNext,
    LayoutViewWrapperNarrow,
  },

  setup() {
    const nextRoute = {
      name: ROUTE_REGISTRATION_IDENTIFICATION_NAME,
    };

    const router = useRouter();

    const state = reactive({
      login: "",
      password: "",
      isValidLogin: false,
      isValidPassword: false,
    });

    const isButtonDisabled = computed(() => {
      if (state.isValidLogin && state.isValidPassword) {
        return false;
      }
      return true;
    });

    const { handleLocalPreRegistration } = useAuth();
    const handlePreRegistration = async () => {
      await handleLocalPreRegistration(state.login, state.password);
      router.push(nextRoute);
    };

    return {
      state,
      isButtonDisabled,
      handlePreRegistration,
    };
  },
};
</script>

<style scoped>
.registration-email {
  padding-bottom: var(--header-default--height);
}
.content {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 60px;
}
.wrapper-inputs {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}

@media (max-width: 1200px) {
  .registration-email {
    padding-bottom: 0px;
  }
  .content {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 80px;
  }
}
</style>
